import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "the-ten-essential-principles-of-taijiquan"
    }}>{`The Ten Essential Principles of Taijiquan`}</h3>
    <ol>
      <li parentName="ol">{`Empty, lively, pushing up, and energetic.`}</li>
      <li parentName="ol">{`Hollow the chest and round the back.`}</li>
      <li parentName="ol">{`“Relax” the “Waist”.`}</li>
      <li parentName="ol">{`Separate Empty and Full.`}</li>
      <li parentName="ol">{`Sink the shoulders and the elbows.`}</li>
      <li parentName="ol">{`Use Intent rather than Force.`}</li>
      <li parentName="ol">{`Synchronize Upper and Lower body.`}</li>
      <li parentName="ol">{`Match up Inner and Outer.`}</li>
      <li parentName="ol">{`Practice continuously without interruption.`}</li>
      <li parentName="ol">{`Seek stillness within movement.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      